<div class="d-flex flex-column">
  <div
    *ngIf="panel.link"
    class="row g-0 box--panel-link"
    [routerLink]="panel.link"
  >
    <div *ngIf="!!profPic" class="col-auto box--img">
      <img [src]="profPic" />
    </div>
    <ng-container *ngIf="!profPic">
      <div *ngIf="!!acronym" class="col-auto txt--acronym">{{ acronym }}</div>
      <div *ngIf="!acronym" class="col-auto box--img">
        <img class="default" src="assets/img/default-user.png" />
      </div>
    </ng-container>
    <div class="col d-flex flex-column justify-content-center">
      <div
        class="txt--full-name"
        [matTooltip]="authData?.tokenAuth?.user_details?.email"
      >
        {{ fullName }}
      </div>
      <div class="txt--view-profile" *ngIf="panel.showDepartment && authData?.tokenAuth?.user_details?.department_name">
        {{ authData?.tokenAuth?.user_details?.department_name }}
      </div>
      <div class="txt--view-profile" *ngIf="panel.viewProfile">
        {{ "common.profile.view" | translate }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="authData?.tokenAuth?.user_details?.is_staff">
    <a class="d-flex box--goto" [href]="admin" target="_blank">
      <div>{{ "msg.adminDashboard" | translate }}</div>
      <i class="bi bi-chevron-right"></i>
    </a>
    <div class="hr"></div>
  </ng-container>
  <div
    *ngIf="
      modules.feeds ||
      authData?.tokenAuth?.organization_settings?.enable_nominations === 1 ||
      modules.settings ||
      panel.support ||
      panel.dealerManagement
    "
    class="d-flex flex-column box--action modules"
  >
    <div
      *ngIf="modules.feeds"
      class="d-flex box--action-item"
      (click)="goToUserFeeds()"
    >
      <div class="box--action-icon">
        <img src="assets/img/feeds.svg" />
      </div>
      <div class="text--settings">{{ "common.myFeeds" | translate }}</div>
      <div
        *ngIf="
          authData?.tokenAuth?.organization_settings?.enable_nominations ===
            1 &&
          authData?.showApprovals &&
          authData?.approvalsCounter &&
          myFeeds.approvals
        "
        class="txt--approvals"
      >
        {{ authData?.approvalsCounter }}
      </div>
    </div>
    <div
      *ngIf="
        authData?.tokenAuth?.user_details?.is_line_manager &&
        panel.dealerManagement
      "
      class="d-flex box--action-item"
      [routerLink]="['/dealer-management']"
    >
      <div class="box--action-icon">
        <img src="assets/img/dealer-management.png" />
      </div>
      <div class="text--settings">
        {{ "common.dealerManagement._" | translate }}
      </div>
    </div>
    <div
      *ngIf="
        modules.feeds &&
        authData?.tokenAuth?.organization_settings?.enable_nominations === 1
      "
      class="d-flex box--action-item"
      [routerLink]="['/my-nominations/list']"
    >
      <div class="box--action-icon">
        <img src="assets/img/so-settings.png" />
      </div>
      <div class="text--settings">{{ "common.myNominations" | translate }}</div>
    </div>
    <div
      *ngIf="modules.settings"
      class="d-flex box--action-item"
      [routerLink]="['/settings']"
    >
      <div class="box--action-icon">
        <i class="bi bi-gear-fill"></i>
      </div>
      <div class="text--settings">{{ "common.settings" | translate }}</div>
    </div>
    <div
      *ngIf="panel.support"
      class="d-flex box--action-item"
      [routerLink]="['/app-support']"
    >
      <div class="box--action-icon">
        <img src="assets/img/so-faq.png" />
      </div>
      <div class="text--settings">{{ "common.support" | translate }}</div>
    </div>
  </div>
  <div class="box--action" [ngClass]="actionClass" Í *ngIf="panel.signOut">
    <div class="d-flex flex-row box--action-item" (click)="signOut()">
      <div class="box--action-icon">
        <div class="box__img--power-button">
          <img src="assets/img/power-button.png" width="12px" height="12px" />
        </div>
      </div>
      <div class="text--sign-out">
        {{ "authentication.signOut" | translate }}
      </div>
    </div>
  </div>
</div>
