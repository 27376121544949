<div class="footer-container" [ngClass]="stickyClass">
  <div class="container px-0">
    <div class="row">
      <div class="col-12 col-md-4">
        <ng-container *ngIf="!!logo2">
          <a *ngIf="isLink(footerLogoLink)" [href]="footerLogoLink">
            <img
              class="img-fluid img--logo"
              *ngIf="footerDynamicLogo"
              [src]="logo2"
            />
            <img
              class="img-fluid img--logo"
              *ngIf="!footerDynamicLogo"
              src="assets/img/org/logo-footer.svg"
            />
          </a>
          <div *ngIf="!isLink(footerLogoLink)">
            <img
              class="img-fluid img--logo"
              *ngIf="footerDynamicLogo"
              [src]="logo2"
              [routerLink]="[footerLogoLink]"
            />
            <img
              class="img-fluid img--logo"
              *ngIf="!footerDynamicLogo"
              src="assets/img/org/logo-footer.svg"
            />
          </div>
        </ng-container>
        <div *ngIf="!logo2">
          <a *ngIf="isLink(footerLogoLink)" [href]="footerLogoLink">
            <img
              class="img-fluid img--logo"
              src="assets/img/org/logo-footer.svg"
            />
          </a>
          <img
            *ngIf="!isLink(footerLogoLink)"
            class="img-fluid img--logo"
            src="assets/img/org/logo-footer.svg"
            [routerLink]="[footerLogoLink]"
          />
        </div>
        <span class="col text2 mt-2">&copy; {{ year }}</span>
      </div>
      <div *ngIf="showStaticContactUS" class="col-12 col-md-4">
        <p class="text1">{{ "common.contactUs" | translate }}</p>
        <p class="text-bold">E-mail: {{ contactEmail }} ​</p>
        <p class="text-bold">Tel. no.:{{ contactNumber }}</p>
      </div>
      <div class="col-12 col-md-4">
        <span *ngIf="footerUserGuideLink && showResource" class="text1">
          {{ "common.resources" | translate }}
        </span>
        <a
          *ngIf="footerUserGuideLink && isLink(footerUserGuideLink)"
          class="link"
          [href]="footerUserGuideLink2"
          target="_blank"
        >
          {{ "common.userGuide" | translate }}
        </a>
        <a
          *ngIf="footerUserGuideLink && !isLink(footerUserGuideLink)"
          class="link"
          [routerLink]="footerUserGuideLink2"
        >
          {{ "common.userGuide" | translate }}
        </a>
        <br *ngIf="footerUserGuideLink" />
        <span class="text1">{{ "common.version" | translate }}</span>
        <br />
        <span class="text2">{{ ver }}</span>
      </div>

      <div class="col-12 col-md-4">
        <span *ngIf="showContactUS || footerPrivacyLink || footerTnCLink" class="text1">
          {{ "common.about" | translate }}
        </span>
        <span class="link" (click)="openContactUs()" *ngIf="showContactUS">
          {{ "common.contactUs" | translate }}
        </span>
        <a *ngIf="footerPrivacyLink != '' && isLink(footerPrivacyLink)" class="link" [href]="footerPrivacyLink"
          target="_blank">
          {{ "common.privacy" | translate }}
        </a>
        <a *ngIf="footerPrivacyLink != '' && !isLink(footerPrivacyLink)" class="link" [routerLink]="footerPrivacyLink">
          {{ "common.privacy" | translate }}
        </a>
      
        <a *ngIf="footerTnCLink && isLink(footerTnCLink)" class="link" [href]="footerTnCLink" target="_blank">
          {{ "common.termsOfUse" | translate }}
        </a>
        <a *ngIf="footerTnCLink && !isLink(footerTnCLink)" class="link" [routerLink]="footerTnCLink">
          {{ "common.termsOfUse" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
