// timer.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from './api-services/api.service';
import { clearLocalStorage } from '../utility/local-storage';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private timerStopped = new Subject<void>();
  private initialTime = 0; // Store the initial time fetched from API
  private remainingTime = new BehaviorSubject<number>(0);
  isSSOLoginSupport = environment.settings.isSSOLoginSupport;
  websiteLink = environment.url.SSOUrl;
  private onTimerStopCallback: (() => void) | null = null;

  constructor(
    private api: ApiService,
    private router: Router,
  ) {
    this.fetchInitialTime();
  }

  fetchInitialTime() {
    this.getSessionTime().then((res) => {
      const currentTime = localStorage.getItem('currentTimer');
      if (currentTime) {
        this.initialTime = parseInt(currentTime);
      } else {
        this.initialTime = res.timeout_period;
      }

      this.remainingTime.next(this.initialTime);
      this.startTimer();
    });
  }

  resetTimer() {
    this.remainingTime.next(this.initialTime); // Reset the time to the initial value
  }

  startTimer() {
    this.stopTimer(); // Ensure any previous timer is stopped
    localStorage.setItem('timerSession', 'started');
    interval(1000)
      .pipe(
        switchMap(() => {
          const currentTime = this.remainingTime.value;
          if (currentTime > 0) {
            this.remainingTime.next(currentTime - 1);
          }
          return of(currentTime);
        }),
        takeUntil(this.timerStopped),
      )
      .subscribe();
  }

  getTime() {
    console.log('jjj');
    return this.remainingTime.asObservable().pipe(
      switchMap(async (seconds) => {
        console.log(seconds);
        if (seconds != 0) {
          localStorage.setItem('currentTimer', seconds.toString());
        }

        if (seconds <= 0) {
          this.stopTimer();
          if (this.initialTime != 0) {
            await this.api.logOut();
            clearLocalStorage();
            this.api.clearCacheStorage();
            if (this.isSSOLoginSupport) {
              window.location.href = environment.url.SSOUrl;
            } else {
              this.router.navigate(['/login']);
            }
          }
        }
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${this.pad(minutes)}:${this.pad(secs)}`;
      }),
    );
  }

  stopTimer() {
    this.timerStopped.next(); // Stop the timer
    localStorage.removeItem('timerSession');
    if (this.onTimerStopCallback) {
      this.onTimerStopCallback(); // Execute the custom code
    }
  }

  setOnTimerStopCallback(callback: () => void) {
    this.onTimerStopCallback = callback;
  }

  private pad(value: number): string {
    return value.toString().padStart(2, '0');
  }

  getSessionTime() {
    return this.api.getSessionTimer();
  }
}
