import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotifModule } from './notif/notif.module';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileModule } from './profile/profile.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InputModule } from '../search/input/input.module';
import { MatListModule } from '@angular/material/list';
import { NgClickOutsideDirective } from 'ng-click-outside2';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsComponent } from './footer/contact-us/contact-us.component';
import { ArrowCircleComponent } from './arrow-circle/arrow-circle.component';
import { LayoutComponent } from './layout/layout.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { LanguageChangeComponent } from './language-change/language-change.component';
import { FlexDollarsSummaryComponent } from './flex-dollars-summary/flex-dollars-summary.component';
import { PointDetailComponent } from './point-detail/point-detail.component';
import { AddEcardComponent } from './add-ecard/add-ecard.component';
import { AddGifComponent } from './add-gif/add-gif.component';
import { AddImageComponent } from './add-image/add-image.component';
import { BoxCloseComponent } from './box-close/box-close.component';
import { FloatAddBtnComponent } from './float-add-btn/float-add-btn.component';
import { ImageBtnComponent } from './image-btn/image-btn.component';
import { CartComponent } from './cart/cart.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { AmplifyComponent } from './amplify/amplify.component';
import { AmplifyModalComponent } from './amplify/amplify-modal/amplify-modal.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { CartHeaderComponent } from './cart-header/cart-header.component';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import { FillerComponent } from './filler/filler.component';
import { ImageShowComponent } from './image-show/image-show.component';
import { AddFileComponent } from './add-file/add-file.component';
import { AddReceiptAttachmentComponent } from './add-receipt-attachment/add-receipt-attachment.component';
import { AddReceiptSuccessComponent } from './add-receipt-success/add-receipt-success.component';
import { ReceiptAttachmentPreviewComponent } from './receipt-attachment-preview/receipt-attachment-preview.component';
import { UploadReceiptComponent } from './upload-receipt/upload-receipt.component';
import { AddReceiptImageComponent } from './upload-receipt/add-receipt-image/add-receipt-image.component';
import { ProcessReceiptImageComponent } from './upload-receipt/process-receipt-image/process-receipt-image.component';
import { EditReceiptImageComponent } from './upload-receipt/edit-receipt-image/edit-receipt-image.component';
import { UseOcrDataComponent } from './upload-receipt/use-ocr-data/use-ocr-data.component';
import { ReceiptListLoaderComponent } from './receipt-list-loader/receipt-list-loader.component';
import { ReceiptFormLoaderComponent } from './receipt-form-loader/receipt-form-loader.component';
import { ReceiptDetailsLoaderComponent } from './receipt-details-loader/receipt-details-loader.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { ConsentBoxComponent } from './consent-box/consent-box.component';
import { ServiceBannerComponent } from './service-banner/service-banner.component';
import { GreetingsComponent } from './greetings/greetings.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TimerComponent } from './timer/timer.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ContactUsComponent,
    ArrowCircleComponent,
    LayoutComponent,
    BreadcrumbComponent,
    ProgressBarComponent,
    SpinnerComponent,
    LanguageChangeComponent,
    FlexDollarsSummaryComponent,
    PointDetailComponent,
    AddEcardComponent,
    AddGifComponent,
    AddImageComponent,
    BoxCloseComponent,
    FloatAddBtnComponent,
    ImageBtnComponent,
    CartComponent,
    LoadMoreComponent,
    AmplifyComponent,
    AmplifyModalComponent,
    TopMenuComponent,
    CartHeaderComponent,
    BottomMenuComponent,
    FillerComponent,
    ImageShowComponent,
    AddFileComponent,
    AddReceiptAttachmentComponent,
    AddReceiptSuccessComponent,
    ReceiptAttachmentPreviewComponent,
    UploadReceiptComponent,
    AddReceiptImageComponent,
    ProcessReceiptImageComponent,
    EditReceiptImageComponent,
    UseOcrDataComponent,
    ReceiptListLoaderComponent,
    ReceiptFormLoaderComponent,
    ReceiptDetailsLoaderComponent,
    AlertModalComponent,
    LoaderComponent,
    ConsentBoxComponent,
    ServiceBannerComponent,
    LoaderComponent,
    GreetingsComponent,
    QrScannerComponent,
    TimerComponent,
  ],
  exports: [
    ArrowCircleComponent,
    LayoutComponent,
    ProgressBarComponent,
    SpinnerComponent,
    LanguageChangeComponent,
    FlexDollarsSummaryComponent,
    PointDetailComponent,
    AddEcardComponent,
    AddGifComponent,
    AddImageComponent,
    BoxCloseComponent,
    FloatAddBtnComponent,
    ImageBtnComponent,
    CartComponent,
    LoadMoreComponent,
    AmplifyComponent,
    BottomMenuComponent,
    FillerComponent,
    AddFileComponent,
    ReceiptListLoaderComponent,
    ReceiptFormLoaderComponent,
    ReceiptDetailsLoaderComponent,
    LoaderComponent,
    GreetingsComponent,
    QrScannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NotifModule,
    ProfileModule,
    MatSelectModule,
    TranslateModule.forChild({ extend: true }),
    FormsModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    ImageCropperModule,
    InputModule,
    MatListModule,
    NgClickOutsideDirective,
    ZXingScannerModule
  ],
})
export class AppCommonModule {}
