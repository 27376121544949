import { Component, inject, OnInit } from '@angular/core';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimerService } from '../../services/timer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss',
})
export class TimerComponent implements OnInit {
  showSessionTimer = environment.settings.showSessionTimer;
  constructor(private timerService: TimerService) {}
  ngOnInit(): void {
    if (this.showSessionTimer) {
      let sessionStartedalready = localStorage.getItem('timerSession');
      if (sessionStartedalready != 'started') {
        this.timerService.resetTimer();
        this.startTimer();
      }
    }
  }
  time$ = this.timerService.getTime();
  alertModal = inject(NgbModal);

  openDialog() {
    const modalRef = this.alertModal.open(AlertModalComponent);
    modalRef.componentInstance.timer = this.time$;
  }

  startTimer() {
    this.timerService.fetchInitialTime(); // Fetch the initial time from the API
    this.timerService.startTimer(); // Start the timer
    this.time$ = this.timerService.getTime();
  }
}
