import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
  activeModal = inject(NgbActiveModal);
  @Input() public timer;
  @Input() public showAlertIcon = false

  closeModal() {
    this.activeModal.dismiss();
  }
}
