<div class="d-flex flex-column box--dialog">
  <img src="assets/img/order-placed.png" width="64px" height="64px" />
  <div class="text--title">{{ "common.orderPlaced" | translate }}</div>
  <div class="text--success">{{ "msg.orderSuc" | translate }}</div>
  <!-- with cart -->
  <ng-container *ngIf="cartDetails">
    <div class="hr"></div>
    <app-cart [editable]="false" [cartDetails]="cartDetails"></app-cart>

    <div class="payment-details justify-content-center">
      <div class="row">
        <div class="col text-center">
          <div class="text--point-yellow" *ngIf="!data?.isOnlinePaymentCart">
            {{ data.pointsDeducted | number }}
            {{ "common.fromWalletPoint" | translate }}
          </div>
          <div class="text--point-yellow" *ngIf="data.isOnlinePaymentCart">
            {{ cartDetails?.user_points }}
            {{ "common.fromWalletPoint" | translate }}
          </div>
          <div class="text--point">{{ "common.fromWallet" | translate }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center" *ngIf="data.isOnlinePaymentCart">
          <div class="text--point-yellow">
            {{ authData?.tokenAuth?.currency }} {{ redeemableDetails.totalAmountPayable | number }}
          </div>
          <div class="text--point">
            {{ "common.fromAccountCredit" | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- without cart -->
  <ng-container *ngIf="data.reward">
    <div class="box--img">
      <img [src]="icon(data.reward)" width="146px" height="110px" />
    </div>
    <div class="text--name">{{ data.reward.name }}</div>
    <div class="text--qty">
      {{ "common.quantity" | translate }}: {{ data.qty }}
    </div>
    <div class="row payment-details justify-content-center">
      <div class="col-auto" *ngIf="showPoints">
        <div class="text--point-yellow">
          {{ data.pointsDeducted | number }}
          {{ "common.fromWalletPoint" | translate }}
        </div>
        <div class="text--point">{{ "common.fromWallet" | translate }}</div>
      </div>
      <div class="col-auto text-end" *ngIf="data.isOnlinePayment">
        <div class="text--point-yellow">
          {{ authData?.tokenAuth?.currency }} {{ data.totalAmountPayable | number }}
        </div>
        <div class="text--point">
          {{ "common.fromAccountCredit" | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="hasAddress">
      <div class="text--delivery-1">
        {{ "common.deliveryAddress" | translate }}
      </div>
      <div class="text--delivery-2">{{ addressDisplay1 }}</div>
      <div class="text--delivery-2">{{ addressDisplay2 }}</div>
    </div>
  </ng-container>
  <div class="box--view" (click)="viewOrder()">
    <span [innerHTML]="'common.viewOrder' | translate"></span>
  </div>
  <div *ngIf="showBackToWebsiteBtn" class="box--view-outline">
    <a target="_blank" (click)="signOut()" [innerHTML]="'common.backToWebsite' | translate"></a>
  </div>
  <div class="box--space"></div>
</div>
